import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../templates/layout";
import useSiteMetadata from '../hooks/metadata';

export default function Template({
  location, data
}) {
  const { siteUrl } = useSiteMetadata();
  const shareLink = `${siteUrl}${location.pathname}`
  const { markdownRemark: post } = data
  return (
    <Layout>
      <div className="single-post max-w-4xl mx-auto mt-10 px-6 lg:px-0">
        <div className="text-center mb-10">
          <h1 className="font-extrabold text-4xl md:text-6xl leading-tighter tracking-tight mb-2">{post.frontmatter.title}</h1>
          <p>by {post.frontmatter.author ? post.frontmatter.author : "Anne Dorko"}</p>
        </div>
        <div
          className="post-content max-w-2xl mx-auto"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <div className="text-center mt-20 pt-5 border-t">
          <p className="text-sm">First published {post.frontmatter.date} {post.frontmatter.updated && <>
            • Updated {post.frontmatter.updated}
          </>}</p>
        </div>
        <div className="mt-10 text-center max-w-xl mx-auto">
          <p className="font-extrabold text-2xl md:text-4xl leading-tighter tracking-tight mb-2">Enjoyed this post? Share it on Twitter! It makes us smile to see it.</p>
          <p>
            <a href={"https://twitter.com/intent/tweet?text=" + post.frontmatter.title + "&url=" + shareLink + "&via=withoutboxes&related=annedorko"} className="transition-all ease-in-out duration-300 bg-cyan-500 text-white rounded p-2 font-bold hover:bg-cyan-800" target="_blank" rel="noreferrer">Tweet This</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($slug: String!) {
    markdownRemark(frontmatter: { permalink: { regex: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "MMMM DD, YYYY")
        permalink
        title
        author
        _yoast_wpseo_title
        _yoast_wpseo_metadesc
      }
    }
  }
`

export const Head = ({ data }) => {
  const { markdownRemark: post } = data // data.markdownRemark holds your post data
  return (
    <>
      <title>{post.frontmatter._yoast_wpseo_title ? post.frontmatter._yoast_wpseo_title : post.frontmatter.title}</title>
      {post.frontmatter._yoast_wpseo_metadesc &&
        <meta name="description" content={post.frontmatter._yoast_wpseo_metadesc}></meta>
      }
    </>
  )
}
